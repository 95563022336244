'use client';

import { CSSProperties } from 'react';
import { Image } from '@akinon/next/components/image';
import { Link } from '@theme/components';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import ScrollableContainer from '@theme/components/scrollableContainer';

export default function CardGridContent({
  data,
  classes = '',
  isScrollable = false
}) {

  const cardArray = [
    data?.attributes?.card_1,
    data?.attributes?.card_2,
    data?.attributes?.card_3,
    data?.attributes?.card_4
  ].filter(card => card !== undefined);

  const cards = isScrollable ? data?.attributes?.card : cardArray.filter(card => card !== undefined);

  const backgroundImageLeft =
    data?.attributes?.background_image_left?.kwargs?.url;
  const backgroundImageRight =
    data?.attributes?.background_image_right?.kwargs?.url;

  const renderCard = (card, index, classes = '') => {
    const link = card?.value?.link;
    const image = card?.kwargs?.value?.image?.url;
    const title = card?.value?.title;
    const cardWidth: number =
      typeof window !== 'undefined'
        ? (window.innerWidth * 92.4) / 100 / 7.5
        : 0;

    const style = {
      '--card-width': `${cardWidth}px`,
      '--card-row-start': index % 2 === 0 ? '1' : '2',
      '--card-col-start': Number(index)
    } as CSSProperties;

    return (
      <Link
        href={link}
        className={twMerge(
          'w-full relative group flex flex-col lg:transition-all overflow-hidden lg:duration-[1s] lg:ease-[cubic-bezier(0.68,-0.55,017,1.55)] z-[1]',
          'max-lg:!w-[13.125rem] max-lg:!h-[15.9375rem] row-start-[var(--card-row-start)] col-start-[var(--card-col-start)]',
          classes,
          clsx({ 'min-w-[var(--card-width)] lg:min-w-[210px]': isScrollable })
        )}
        style={style}
        key={image}
      >
        <p
          draggable={false}
          className="select-none card-title-wrapper absolute top-0 left-0 w-full z-[1] flex justify-between items-center max-lg:bg-primary backdrop-blur text-white lg:text-black capitalize lg:group-hover:bg-primary lg:group-hover:bg-opacity-70 lg:group-hover:backdrop-blur lg:group-hover:text-white lg:transition-all lg:duration-[1s] lg:ease-[cubic-bezier(0.68,-0.55,0.27,1.55)] lg:group-hover:p-4 max-lg:p-3 shrink-0 max-lg:bg-opacity-[72%] bg-white hover:bg-none"
        >
          <span
            className={clsx(
              'text-base font-kronaOne',
              isScrollable ? 'lg:text-base' : 'lg:text-2xl'
            )}
          >
            {title}
          </span>
          <span className="text-xs lg:text-sm lg:text-plum-cheese lg:group-hover:text-white lg:transition-all lg:duration-[1s] lg:ease-[cubic-bezier(0.68,-0.55,0.27,1.55)]">
            {index}
          </span>
        </p>
        <Image
          draggable={false}
          src={image}
          alt={title}
          width={210}
          height={222}
          className="flex items-end lg:pt-[2.9375rem] flex-1 lg:transition-all lg:duration-[1s] lg:ease-[cubic-bezier(0.68,-0.55,0.27,1.55)]"
          imageClassName="select-none card-image w-full lg:bg-white h-[calc(100%+2.9375rem)] translate-y-[2.9375rem] lg:transition-all lg:duration-[1s] lg:ease-[cubic-bezier(0.68,-0.55,0.27,1.55)] lg:group-hover:translate-y-0 lg:group-hover:scale-105"
        />
      </Link>
    );
  };

  return (
    <div className={twMerge('relative w-full lg:py-[4.5rem] py-8 ', classes)}>
      <ScrollableContainer
        isScrollable={isScrollable}
        wrapperClass={twMerge(
          '[&::-webkit-scrollbar]:h-1 [&::-webkit-scrollbar-track]:bg-cerebral-grey [&::-webkit-scrollbar-thumb]:bg-basalt-grey w-full grid grid-rows-2 gap-x-[2.25rem]',
          clsx(
            isScrollable
              ? 'lg:w-[92.4%] ms-auto overflow-x-auto pb-12 -mb-[.6875rem]'
              : 'max-w-screen-2.5xl mx-auto relative max-lg:overflow-x-auto -mb-[1.3125rem]'
          )
        )}
      >
        <>
          {cards ? cards.map((card, index) => {
                return renderCard(
                  card,
                  `${index < 9 ? '0' : ''}${index + 1}`,
                  `${
                    isScrollable
                      ? 'odd:-translate-y-[.6875rem]'
                      : 'odd:-translate-y-[1.3125rem]'
                  }`
                );
          }) : null}

          {backgroundImageLeft && !isScrollable && (
            <Image
              draggable={false}
              src={backgroundImageLeft}
              alt={' '}
              className="absolute top-0 right-auto max-lg:-left-[2.875rem] lg:right-[calc(100%-8.5625rem)] lg:w-[25.0625rem] lg:h-[25.0625rem] h-[13.25rem] w-[13.25rem]"
              width={401}
              height={401}
            />
          )}

          {backgroundImageRight && !isScrollable && (
            <Image
              draggable={false}
              src={backgroundImageRight}
              alt={' '}
              className="absolute bottom-0 left-auto max-lg:-right-[2.5rem] lg:left-[calc(100%-3.25rem)] lg:w-[10.3125rem] lg:h-[10.3125rem] h-[7.5625rem] w-[7.5625rem]"
              width={165}
              height={165}
            />
          )}
        </>
      </ScrollableContainer>
    </div>
  );
}
