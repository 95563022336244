'use client';

import React from 'react';
import { Link } from '@theme/components';
import { WidgetResultType } from '@akinon/next/types';
import { calculateIsExpired } from '@theme/utils/calculateIsExpired';
import { IHomeHeroVideo } from '@theme/types';
// eslint-disable-next-line @akinon/projectzero/image-import
import { unstable_getImgProps as getImageProps } from 'next/image';
import ArrowRightIcon from '@theme/components/icons/arrowRight';
import ArrowLeftIcon from '@theme/components/icons/arrowLeft';
export default function HomeHeroVideoContent({
  data,
  swiperId
}: {
  data: WidgetResultType<IHomeHeroVideo>;
  swiperId: string;
}) {
  const heroSliderData = data?.attributes?.hero_slider;
  const autoPlay = data?.attributes?.auto_play?.value;

  const sliderEndDate = heroSliderData
    .filter((slider) => new Date(slider?.value?.end_date) > new Date())
    .map((slider) => new Date(slider?.value?.end_date).toISOString());

  const common = {
    className: 'w-full object-cover h-full',
    priority: true
  };

  const renderSlideImage = (slide) => {
    const {
      props: { srcSet: desktop }
    } = getImageProps({
      width: 3456,
      height: 1440,
      quality: 100,
      src: slide?.kwargs?.value?.image_video_gif?.url,
      alt: slide?.value?.alt,
      ...common
    });
    const {
      props: { srcSet: mobile, ...rest }
    } = getImageProps({
      width: 750,
      height: 984,
      quality: 100,
      src: slide?.kwargs?.value?.mobile_image_video_gif?.url,
      alt: slide?.value?.alt,
      ...common
    });

    return (
      <Link draggable="false" href={slide?.value?.url} className="block h-full">
        <picture className="block h-full">
          <source media="(min-width: 1024px)" srcSet={desktop} />
          <source srcSet={mobile} />
          <img {...rest} />
        </picture>
      </Link>
    );
  };

  const renderSlideVideo = (slide) => {
    return (
      <Link draggable="false" href={slide?.value?.url} className="block h-full">
        <video
          className="w-full h-full object-cover hidden lg:inline-block"
          muted
          loop
          playsInline
          autoPlay
        >
          <source
            src={slide?.kwargs?.value?.image_video_gif?.url}
            type="video/mp4"
          />
        </video>
        <video
          className="w-full h-full object-cover lg:hidden"
          muted
          loop
          playsInline
          autoPlay
        >
          <source
            src={slide?.kwargs?.value?.mobile_image_video_gif?.url}
            type="video/mp4"
          />
        </video>
      </Link>
    );
  };

  const renderSlides = () => {
    return heroSliderData?.map((slide) => {
      const isExpired = calculateIsExpired(slide?.value?.end_date);

      if (isExpired && !!slide?.value?.end_date) {
        return null;
      }

      const isVideo = slide?.kwargs?.value?.media_type?.value === 'video';

      return (
        <swiper-slide
          key={JSON.stringify(slide)}
          className={'swiper-slide-active group relative h-full w-full'}
        >
          {isVideo ? renderSlideVideo(slide) : renderSlideImage(slide)}
        </swiper-slide>
      );
    });
  };

  return (
    <div className="home-hero-slider mb-[.875rem] lg:mb-[2.1875rem]">
      <div className="relative">
        <swiper-container
          slides-per-view="auto"
          id={swiperId}
          class="h-auto"
          navigation="true"
          navigation-next-el={'#custom-nav-button--next-' + swiperId}
          navigation-prev-el={'#custom-nav-button--prev-' + swiperId}
          autoplay={autoPlay}
          loop="true"
        >
          {heroSliderData && renderSlides()}
        </swiper-container>

        {sliderEndDate?.length > 1 && (
          <>
            <button
              id={'custom-nav-button--prev-' + swiperId}
              className="absolute z-[8] top-1/2 4xl:left-12 left-8 max-lg:hidden disabled:opacity-30 w-[3.25rem] h-[3.25rem]"
            >
              <ArrowLeftIcon
                className={'w-[1.25rem] h-[1.25rem] ml-[0.8rem]'}
              />
            </button>
            <button
              id={'custom-nav-button--next-' + swiperId}
              className="absolute z-[8] top-1/2 4xl:right-12 lg:right-8 max-lg:hidden disabled:opacity-30 w-[3.25rem] h-[3.25rem]"
            >
              <ArrowRightIcon
                className={'w-[1.25rem] h-[1.25rem] ml-[0.8rem]'}
              />
            </button>
          </>
        )}
      </div>
    </div>
  );
}
