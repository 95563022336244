import clsx from 'clsx';
import React, { useRef, useState, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

const ScrollableContainer = ({
  children,
  wrapperClass,
  isScrollable
}: {
  children: JSX.Element;
  wrapperClass: string;
  isScrollable: boolean;
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startPoint, setStartPoint] = useState(0);
  const [initialScrollLeft, setInitialScrollLeft] = useState(0);

  const handleMouseDown = (e: React.MouseEvent) => {
    if (!containerRef.current) return;
    setIsDragging(true);
    setStartPoint(e.clientX);
    setInitialScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragging || !containerRef.current) return;
    const x = e.clientX;
    const walk = (x - startPoint); // Scroll speed
    containerRef.current.scrollLeft = initialScrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  return (
    <div
      className={twMerge(
        clsx(
          isScrollable ? (isDragging ? 'cursor-grabbing' : 'cursor-grab') : ''
        ),
        wrapperClass
      )}
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={(e) => {
        e.stopPropagation()
        isDragging && setIsDragging(false)}}
    >
      {children}
    </div>
  );
};

export default ScrollableContainer;
