'use client';

import { Link, Price } from '@theme/components';
import styles from './pointer-mono-images.module.css';
import { twMerge } from 'tailwind-merge';
import { Tooltip } from 'react-tooltip';
import { Image } from '@akinon/next/components/image';
import useWindowResize from '@theme/hooks/use-window-resize';

interface Value {
  title: string;
  image: string;
  point_device: string;
  desktop_position_top: string;
  text_color: string;
  link: string;
  desktop_position_left: string;
  mobile_position_left: string;
  mobile_position_top: string;
  background_color: string;
  price: string;
  id: string;
}

interface KwargsValue {
  image: {
    url: string;
    data_type: string;
    value: string;
  };
  point_device: {
    data_type: string;
    value: string;
  };
}

interface Data {
  value: Value;
  kwargs: {
    data_type: string;
    value: KwargsValue;
  };
}

export default function Points({ point }: { point: Data }) {
  const { width } = useWindowResize();

  return (
    <>
      {width >= 1024 ? (
        <Link
          href={point?.value?.link ?? '/'}
          className={twMerge(
            'group p-px absolute w-4 h-4 border border-white rounded-full lg:cursor-pointer',
            styles.point,
            point?.value?.point_device == 'mobile'
              ? 'block lg:hidden'
              : point?.value?.point_device == 'desktop'
              ? 'hidden lg:block'
              : 'block'
          )}
          style={
            {
              '--point-top-desktop': point?.value?.desktop_position_top + '%',
              '--point-left-desktop': point?.value?.desktop_position_left + '%',
              '--point-top-mobile': point?.value?.mobile_position_top + '%',
              '--point-left-mobile': point?.value?.mobile_position_left + '%'
            } as React.CSSProperties
          }
          data-tooltip-id={point.value.id}
        >
          <div className="w-full h-full bg-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"></div>
        </Link>
      ) : (
        <div
          className={twMerge(
            'group p-px absolute w-4 h-4 border border-white rounded-full lg:cursor-pointer',
            styles.point,
            point?.value?.point_device == 'mobile'
              ? 'block lg:hidden'
              : point?.value?.point_device == 'desktop'
              ? 'hidden lg:block'
              : 'block'
          )}
          style={
            {
              '--point-top-desktop': point?.value?.desktop_position_top + '%',
              '--point-left-desktop': point?.value?.desktop_position_left + '%',
              '--point-top-mobile': point?.value?.mobile_position_top + '%',
              '--point-left-mobile': point?.value?.mobile_position_left + '%'
            } as React.CSSProperties
          }
          data-tooltip-id={point?.value?.id}
        >
          <div className="w-full h-full bg-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"></div>
        </div>
      )}

      <Tooltip
        id={point.value.id}
        className="flex rounded-lg relative z-40 w-[15.625rem] min-h-[6.25rem] !pointer-events-auto"
        openOnClick={width < 1024}
        style={{
          backgroundColor: point?.value?.background_color,
          color: point?.value?.text_color
        }}
      >
        {point?.value?.link ? (
          <Link href={point?.value?.link ?? '/'} className="flex gap-x-3 relative z-50">
            <Image
              src={point?.kwargs?.value?.image?.url}
              alt={point?.value?.title}
              width={82}
              height={82}
            />
            <div className="flex flex-col gap-y-3 max-w-[14.1875rem]">
              <h3 className="text-xs">{point?.value?.title}</h3>
              <Price className="text-sm" value={point?.value?.price} />
            </div>
          </Link>
        ) : (
          <div className="flex gap-x-3">
            <Image
              src={point?.kwargs?.value?.image?.url}
              alt={point?.value?.title}
              width={82}
              height={82}
            />
            <div className="flex flex-col gap-y-3">
              <h3 className="font-sans text-xs font-medium text-white line-clamp-2">
                {point?.value?.title}
              </h3>
              <Price className="text-sm" value={point?.value?.price} />
            </div>
          </div>
        )}
      </Tooltip>
    </>
  );
}
