'use client';

import { useMediaQuery } from '@akinon/next/hooks';
import { HeritageSliderItem } from '@theme/types';
import clsx from 'clsx';
import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Image } from '@akinon/next/components/image';
import { EffectCards } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-cards';
import { RecommendationButton } from '@theme/components/recommendationButton';
import ArrowRightIcon from '@theme/components/icons/arrowRight';
import ArrowLeftIcon from '@theme/components/icons/arrowLeft';
import { calculateIsExpired } from '@theme/utils/calculateIsExpired';

interface HeritageSliderWidgetProps {
  data: HeritageSliderItem[];
}

const HeritageSliderWidget = (props: HeritageSliderWidgetProps) => {
  const data = props?.data?.filter((item) => {
    const isExpired = calculateIsExpired(item?.value?.end_date);
    // return only non-expired slides
    return !isExpired;
  });
  const matches = useMediaQuery('(min-width: 768px)');
  const [totalSlide, setTotalSlide] = useState(data?.length);
  const [matchesStatus, setMatchesStatus] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);

  useEffect(() => {
    setMatchesStatus(matches);
  }, [matches]);

  const handleChange = (e) => {
    setActiveIndex(e?.activeIndex);
  };

  const goNext = () => {
    if (swiperRef?.current) {
      swiperRef?.current?.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef?.current) {
      swiperRef?.current?.slidePrev();
    }
  };

  const renderSlides = () => {
    return data?.map((item) => {
      return (
        <SwiperSlide
          key={JSON.stringify(item)}
          className={clsx('flex items-center justify-center bg-transparent')}
        >
          <Image
            src={item?.kwargs?.value?.desktop_image?.url}
            alt={item?.value?.alt}
            sizes="878px"
            fill
            aspectRatio={351 / 259}
          />
        </SwiperSlide>
      );
    });
  };

  return (
    <div
      style={
        {
          '--text-color': data?.[activeIndex]?.value?.heritage_text_color,
          '--bg-color': data?.[activeIndex]?.value?.heritage_background_color,
          '--button-color': data?.[activeIndex]?.value?.heritage_button_color,
          '--hover-button-color':
            data?.[activeIndex]?.value?.heritage_hover_button_text_color,
          '--hover-button-bg':
            data?.[activeIndex]?.value?.heritage_hover_button_bg_color,
          '--arrow-color': data?.[activeIndex]?.value?.heritage_arrow_color
        } as React.CSSProperties
      }
      className="w-full mb-8 lg:mb-[4.5rem] transition-all duration-300 delay-300 bg-[var(--bg-color)]"
    >
      {data ? (
        <div className="flex max-lg:flex-col-reverse lg:gap-x-[40px] 2xl:gap-x-[5.75rem] max-lg:gap-y-4 max-w-[77.625rem] lg:py-[5.625rem] py-8 mx-auto px-6">
          <div className="flex flex-col justify-center flex-1 text-left transition-all duration-300 delay-300 text-[var(--text-color)] gap-y-6">
            <div className="md:text-[4rem] text-5xl font-medium font-kalnia">
              {data?.[activeIndex]?.value?.title}
            </div>
            <div className="text-xl md:text-2xl font-kronaOne">
              {data?.[activeIndex]?.value?.subtitle}
            </div>
            <div className="font-sans font-medium">
              {data?.[activeIndex]?.value?.text}
            </div>
            <RecommendationButton
              key={`heritage-${data?.[activeIndex]?.value?.link}-${activeIndex}`}
              hoverTextColor={
                data?.[activeIndex]?.value?.heritage_hover_button_text_color
              }
              hoverBgColor={
                data?.[activeIndex]?.value?.heritage_hover_button_bg_color
              }
              textColor={data?.[activeIndex]?.value?.heritage_text_color}
              text={data?.[activeIndex]?.value?.link_label}
              url={data?.[activeIndex]?.value?.link}
              className="text-sm lg:text-base"
            />
          </div>
          <div className="flex items-center justify-center shrink">
            <Swiper
              className="max-w-[43.875rem] lg:max-w-[35.625rem] xl:max-w-[43.875rem] w-full"
              effect={'cards'}
              grabCursor={true}
              modules={[EffectCards]}
              onSlideChange={handleChange}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
                setTotalSlide(swiper?.slides?.length);
              }}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              }}
            >
              {renderSlides()}
              <div className="flex justify-between mt-6">
                <div className="flex w-[6.25rem] justify-between items-center">
                  <div className="pr-2 text-xs font-medium text-[var(--arrow-color)]">
                    {activeIndex + 1 > 9
                      ? activeIndex + 1
                      : `0${activeIndex + 1}`}
                  </div>
                  <div className="bg-[var(--arrow-color)] min-w-[3.375rem] h-[0.0625rem]"></div>
                  <div className="pl-2 text-xs font-medium text-[var(--arrow-color)]">
                    {swiperRef?.current?.slides?.length > 9
                      ? swiperRef?.current?.slides?.length
                      : `0${totalSlide}`}
                  </div>
                </div>
                <div className="flex">
                  <ArrowLeftIcon
                    onClick={goPrev}
                    className={clsx(
                      'cursor-pointer transition-all duration-300 delay-300 fill-[var(--arrow-color)] w-5 h-5',
                      {
                        'fill-sinister-minister opacity-[0.25] !cursor-default':
                          activeIndex === 0
                      }
                    )}
                  />
                  <ArrowRightIcon
                    onClick={goNext}
                    className={clsx(
                      'cursor-pointer transition-all duration-300 delay-300 fill-[var(--arrow-color)] w-5 h-5',
                      {
                        'fill-sinister-minister opacity-[0.25] !cursor-default':
                          activeIndex === swiperRef?.current?.slides?.length - 1
                      }
                    )}
                  />
                </div>
              </div>
            </Swiper>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default HeritageSliderWidget;
